var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import ManualAllot from "~/components/case-info/manual-allot.vue";
import BatchAllot from "~/components/case-info/batch-allot.vue";
import CaseTypeForm from "~/components/case-info/case-type-form.vue";
import { Layout } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Dependencies } from "~/core/decorator";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
import { StrategyDistributeService } from "~/services/dataimp-service/strategy-distribute.service";
import { State, Getter } from "vuex-class";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { CaseInfoService } from '~/services/business-service/case-info.service';
var OrganizationDistributeCase = /** @class */ (function (_super) {
    __extends(OrganizationDistributeCase, _super);
    function OrganizationDistributeCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.batchNumberList = [];
        _this.model = {
            caseType: "TEL_CASE"
        };
        _this.searchModel = {
            batchNumber: "",
            departCode: "",
            personalName: "",
            caseNumber: "",
            certificateNo: "",
            city: "",
            stage: "",
            delegationDate: [],
            endCaseDate: [],
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            principalId: "",
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            retireCaseDays: "",
            caseType: ''
        };
        _this.deptIds = [];
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
        };
        _this.dialog = {
            regionManualDivision: false,
            batchDivision: false,
            caseChange: false
        };
        return _this;
    }
    /**
     * 机构改变
     */
    OrganizationDistributeCase.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.searchModel.departCode = valueArray[valueArray.length - 1];
    };
    OrganizationDistributeCase.prototype.mounted = function () {
        // 刷新列表
        this.refreshData();
    };
    /**
     * 刷新数据列
     */
    OrganizationDistributeCase.prototype.refreshData = function () {
        var _this = this;
        this.caseInfoService.queryOrganizationDistributeCases(this.searchModel, this.pageService, this.sortService).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    OrganizationDistributeCase.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.departCode = '';
        this.searchModel.caseNumber = '';
        this.deptIds = [];
        this.searchModel.overdueAmtTotal.min = '';
        this.searchModel.overdueAmtTotal.max = '';
        this.searchModel.leftAmt.min = '';
        this.searchModel.leftAmt.max = '';
        this.searchModel.overduePeriods.min = '';
        this.searchModel.overduePeriods.max = '';
        this.searchModel.overdueDays.min = '';
        this.searchModel.overdueDays.max = '';
    };
    /**
     * 搜索条件重置
     */
    OrganizationDistributeCase.prototype.reset = function () {
        var form = this.$refs["form"];
        if (form) {
            form.resetForm();
        }
    };
    /**
     * 策略分配
     */
    OrganizationDistributeCase.prototype.onStrategyAllocation = function () {
        var _this = this;
        // this.dialog.strategyAllocation = true;
        this.$confirm('确定执行策略分案?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(function () {
            _this.loading.state = true;
            _this.strategyDistributeService.manualExecuteStrategy('DEPARTMENT', _this.loading).subscribe(function (data) {
                _this.$message({
                    type: 'success',
                    message: '正在进行策略分案，请稍后刷新页面!'
                });
            });
        }).catch(function () {
            _this.$message({
                type: 'info',
                message: '已取消策略分案'
            });
        });
    };
    OrganizationDistributeCase.prototype.onBatchIssuedOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["org-case-batch-distribute"];
            form.refreshData();
        });
    };
    OrganizationDistributeCase.prototype.onBatchDivision = function () {
        this.dialog.batchDivision = true;
    };
    OrganizationDistributeCase.prototype.changeCaseType = function () {
        if (this.selectionList.length === 0) {
            this.$message.warning("请选择要流转的案件");
            return;
        }
        var caseType = '';
        var flag = false;
        this.selectionList.map(function (e) {
            if (caseType === '') {
                caseType = e.caseType;
            }
            else {
                if (caseType !== e.caseType) {
                    flag = true;
                    return;
                }
                else {
                    caseType = e.caseType;
                }
            }
        });
        if (flag) {
            this.$message.warning("所选案件的调解方式不统一");
            return;
        }
        else {
            this.dialog.caseChange = true;
        }
    };
    OrganizationDistributeCase.prototype.submit = function () {
        var _this = this;
        if (!this.model.caseType) {
            this.$message.warning("请选择调解方式");
            return;
        }
        this.caseInfoService.changeCaseType({ "caseIds": this.selectionList.map(function (x) { return x.id; }), "caseType": this.model.caseType }).subscribe(function (data) {
            _this.dialog.caseChange = false;
            _this.$message.success("批量流转成功，请在流转案件池继续操作!");
            _this.refreshData();
        });
    };
    /**
     * 手动分案
     */
    OrganizationDistributeCase.prototype.manualSeparateCases = function () {
        if (this.selectionList.length === 0) {
            this.$message.warning("请选择要分配的案件");
            return;
        }
        this.dialog.regionManualDivision = true;
        // this.$nextTick(() => {
        //   let form: any = this.$refs["manual-case-allot"] as any;
        //   form.refreshUserData();
        // });
    };
    __decorate([
        Dependencies(PageService)
    ], OrganizationDistributeCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], OrganizationDistributeCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], OrganizationDistributeCase.prototype, "distributeCaseService", void 0);
    __decorate([
        Dependencies(CaseInfoService)
    ], OrganizationDistributeCase.prototype, "caseInfoService", void 0);
    __decorate([
        Dependencies(StrategyDistributeService)
    ], OrganizationDistributeCase.prototype, "strategyDistributeService", void 0);
    __decorate([
        Getter
    ], OrganizationDistributeCase.prototype, "departmentData", void 0);
    __decorate([
        State
    ], OrganizationDistributeCase.prototype, "principalList", void 0);
    OrganizationDistributeCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                NumberRange: NumberRange,
                BatchAllot: BatchAllot,
                PrincipalBatch: PrincipalBatch,
                ManualAllot: ManualAllot,
                CaseTypeForm: CaseTypeForm
            }
        })
    ], OrganizationDistributeCase);
    return OrganizationDistributeCase;
}(Vue));
export default OrganizationDistributeCase;
